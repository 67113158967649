import axios from 'axios';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GlobalScript = () => {
  // Adding GA params if it crosses the domains
  const { pathname } = useLocation();

  useEffect(() => {
    // Step 1: Select all anchor elements
    const loginElements = document.querySelectorAll('[href^="/login"]');
    const registrationElements = document.querySelectorAll(
      '[href^="/registration"]'
    );
    // Combine the NodeLists into a single array
    const allElements = [...loginElements, ...registrationElements];

    allElements.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();

        const glGetter = document.querySelector('#_gl_getter > button');
        const glInput = document.querySelector('#_gl_getter > input');

        if (glGetter) {
          glGetter.click();
        }
        // console.info('_gl_getter', glInput.value);
        if (!glInput || !glInput.value) {
          console.error('_gl_getter', 'no _gl tag from the logic');
        }

        const currentHref = link.getAttribute('href');

        // Append the special parameter to the URL
        const newHref = `${currentHref}${
          currentHref.includes('?') ? '&' : '?'
        }_gl=${glInput && glInput.value ? glInput.value : ''}`;
        // console.info('currentHref', currentHref, newHref);
        // Navigate to the new URL
        window.location.href = newHref;
      });
    });

    // get csrf token data
    const csrfToken = document.querySelector('meta[name="csrf-token"]');
    axios.get('/api/meta/csrf-token').then((res) => {
      csrfToken.setAttribute('content', res.data.csrfToken);
    });
  }, [pathname]);

  // Firing sdc-login event if there is action=login-complete param
  useEffect(() => {
    const urlParams = window.location.search
      .substring(1, window.location.search.length)
      .split('&');
    const params = {};
    urlParams.forEach((param) => {
      const splited = param.split('=');
      if (splited[1]) {
        params[splited[0]] = splited[1];
      }
    });
    const urlObj = new URL(window.location.href);
    if (params.action === 'login-complete') {
      urlObj.searchParams.delete('action');
      window.history.replaceState({}, document.title, urlObj.toString());
      window.gtag('event', 'sdc-login', {
        event_link: window.location.href,
      });
    }
    // get rid of _gl tag
    urlObj.searchParams.delete('_gl');
    window.history.replaceState({}, document.title, urlObj.toString());
  }, []);

  return null;
};

export default GlobalScript;
