import {
  call,
  put,
  takeLatest,
  select,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import * as types from '../ActionTypes';
import { Get, Post, Delete } from '../../utils/Requester';

export function* getBookmarkList() {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return [];
    }

    const { data, error } = yield call(Get, {
      url: `/api/sdc/bookmark`,
    });
    if (error || typeof data === 'string') {
      console.error('UserBookmarkSaga', error);
      return [];
    }
    return data.bookmarkList;
  } catch (error) {
    if (error) {
      console.error('UserBookmarkSaga', error);
      return [];
    }
  }
}

function* addBookmark({ sessionData, actionPage }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.ADD_BOOKMARK_SUCCESS,
        data: [{ ...sessionData }],
        targetId: sessionData.PresentationID,
      });
      return;
    }

    const tag = sessionData.CustomPresField2 || sessionData.category || '';

    const bookmarkData = {
      PresentationID: sessionData.PresentationID,
      PresentationTitle: sessionData.PresentationTitle,
      // sessionData.SessionBuilderCode === 'M' && sessionData.SessionName
      //   ? sessionData.SessionName
      //   : sessionData.PresentationTitle,
      PresentationDateTimeStart: sessionData.PresentationDateTimeStart,
      PresentationDateTimeEnd: sessionData.PresentationDateTimeEnd,
      AbstractTextShort: sessionData.AbstractTextShort,
      SessionType: sessionData.SessionType,
      PresentationImage:
        sessionData.CustomPresField1 || sessionData.image || '',
      PresentationTag: typeof tag === 'string' && tag ? tag.split(',') : tag,
      MainCategory: sessionData.CustomPresField65 || sessionData.mainCategory,
    };

    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    const { data, error } = yield call(Post, {
      url: `/api/sdc/bookmark/${sessionData.PresentationID}`,
      body: { data: bookmarkData },
      headers: {
        'CSRF-Token': token,
      },
    });

    if (error || typeof data === 'string') {
      console.error('UserBookmarkSaga', error);
      yield put({
        type: types.ADD_BOOKMARK_ERROR,
        error: error || 'UNKNOWN_ERROR',
        targetId: sessionData.PresentationID,
      });
      return;
    }

    const addCount = yield call(getBookmarkCount, {
      sessionId: sessionData.PresentationID,
    });

    if (actionPage === 'keynote') {
      yield put({
        type: types.UPDATE_KEYNOTE_COUNT,
        keynoteCount: addCount,
      });
      // keynote bookmark count update
    } else {
      yield put({
        type: types.UPDATE_BOOKMARK_COUNT,
        bookmarkCount: addCount,
      });
    }

    yield put({
      type: types.ADD_BOOKMARK_SUCCESS,
      data: [{ ...bookmarkData }],
      targetId: sessionData.PresentationID,
    });
  } catch (error) {
    if (error) {
      console.error('UserBookmarkSaga', error);
      yield put({
        type: types.ADD_BOOKMARK_ERROR,
        error,
      });
    }
  }
}

function* deleteBookmark({ sessionId, actionPage }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      const stateBookmark = yield select((state) => state.bookmarkList.data);

      yield put({
        type: types.DELETE_BOOKMARK_SUCCESS,
        data: stateBookmark.filter((x) => x.PresentationID !== sessionId),
      });
      return;
    }

    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    const { data, error } = yield call(Delete, {
      url: `/api/sdc/bookmark/${sessionId}`,
      body: { data: { sessionId } },
      headers: {
        'CSRF-Token': token,
      },
    });

    if (error || typeof data === 'string') {
      console.error('UserBookmarkSaga', error);
      yield put({
        type: types.DELETE_BOOKMARK_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    const stateBookmark = yield select((state) => state.bookmarkList.data);
    const newBookmark = stateBookmark.filter(
      (x) => x.PresentationID !== sessionId
    );

    const deleteCount = yield call(getBookmarkCount, {
      sessionId,
    });

    if (actionPage === 'keynote') {
      // keynote bookmark count update
      yield put({
        type: types.UPDATE_KEYNOTE_COUNT,
        keynoteCount: deleteCount,
      });
    } else {
      yield put({
        type: types.UPDATE_BOOKMARK_COUNT,
        bookmarkCount: deleteCount,
      });
    }

    yield put({
      type: types.DELETE_BOOKMARK_SUCCESS,
      data: newBookmark,
      targetId: sessionId,
    });
  } catch (error) {
    if (error) {
      console.error('UserBookmarkSaga', error);
      yield put({
        type: types.DELETE_BOOKMARK_ERROR,
        error,
      });
    }
  }
}

export function* getBookmarkCount({ sessionId }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return 100;
    }
    const { data, error } = yield call(Get, {
      url: `/api/sdc/bookmark/${sessionId}/count`,
    });

    if (error || typeof data === 'string') {
      console.error('UserBookmarkSaga', error);
      return 0;
    }

    return data.bookmarkCount;
  } catch (error) {
    if (error) {
      console.error('UserBookmarkSaga', error);
      return 0;
    }
  }
}

// function* addBookmarkCount({ sessionId }) {
//   try {
//     if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
//       return 100;
//     }
//     const { data, error } = yield call(Post, {
//       url: `/api/sdc/bookmark/${sessionId}/count`,
//     });

//     if (error || typeof data === 'string') {
//       console.error('UserBookmarkSaga', error);
//     }

//     return data.bookmarkCount;
//   } catch (error) {
//     if (error) {
//       console.error('UserBookmarkSaga', error);
//     }
//   }
// }

// function* deleteBookmarkCount({ sessionId }) {
//   try {
//     if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
//       return 100;
//     }
//     const { data, error } = yield call(Delete, {
//       url: `/api/sdc/bookmark/${sessionId}/count`,
//     });

//     if (error || typeof data === 'string') {
//       console.error('UserBookmarkSaga', error);
//     }

//     return data.bookmarkCount;
//   } catch (error) {
//     if (error) {
//       console.error('UserBookmarkSaga', error);
//     }
//   }
// }

export default function* rootSaga() {
  yield takeEvery(types.ADD_BOOKMARK_REQUEST, addBookmark);
  yield takeEvery(types.DELETE_BOOKMARK_REQUEST, deleteBookmark);
}
