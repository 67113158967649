import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../ActionTypes';
import { Get, Post, Delete } from '../../utils/Requester';

export function* getLikedSession(sessionId) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return false;
    }

    const { data, error } = yield call(Get, {
      url: `/api/sdc/like/${sessionId}`,
    });
    if (error || typeof data === 'string') {
      if (error.indexOf('Not Found') > -1) {
        console.warn('NOT_FOUND_LIKED_SESSION');
      } else {
        console.error('UserLikeSaga', error);
      }
      return false;
    }

    if (typeof data.data === 'object') {
      return false;
    }

    return JSON.parse(data.data).PresentationID === sessionId;
  } catch (error) {
    if (error) {
      console.error('UserLikeSaga', error.data);
      return false;
    }
  }
}

function* likeSession({ sessionData }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.LIKE_SESSION_SUCCESS,
      });
      return;
    }

    const tag = sessionData.CustomPresField2 || sessionData.category || '';

    const likeData = {
      PresentationID: sessionData.PresentationID,
      PresentationTitle: sessionData.PresentationTitle,
      PresentationDateTimeStart: sessionData.PresentationDateTimeStart,
      PresentationDateTimeEnd: sessionData.PresentationDateTimeEnd,
      AbstractTextShort: sessionData.AbstractTextShort,
      SessionType: sessionData.SessionType,
      PresentationImage:
        sessionData.CustomPresField1 || sessionData.image || '',
      PresentationTag: typeof tag === 'string' && tag ? tag.split(',') : tag,
      MainCategory: sessionData.mainCategory,
    };

    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');
    const { data, error } = yield call(Post, {
      url: `/api/sdc/like/${sessionData.PresentationID}`,
      body: { data: likeData },
      headers: {
        'CSRF-Token': token,
      },
    });

    if (error || typeof data === 'string') {
      console.error('UserLikeSaga', error);
      yield put({
        type: types.LIKE_SESSION_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    const addCount = yield call(getLikeCount, {
      sessionId: sessionData.PresentationID,
    });

    yield put({ type: types.UPDATE_LIKE_COUNT, likeCount: addCount });

    yield put({
      type: types.LIKE_SESSION_SUCCESS,
    });
  } catch (error) {
    if (error) {
      console.error('UserLikeSaga', error);
      yield put({
        type: types.LIKE_SESSION_ERROR,
        error,
      });
    }
  }
}

function* unlikeSession({ sessionId, actionPage }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      yield put({
        type: types.UNLIKE_SESSION_SUCCESS,
      });
      return;
    }
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    const { data, error } = yield call(Delete, {
      url: `/api/sdc/like/${sessionId}`,
      body: { sessionId },
      headers: {
        'CSRF-Token': token,
      },
    });
    if (error || typeof data === 'string') {
      console.error('UserLikeSaga', error);
      yield put({
        type: types.UNLIKE_SESSION_ERROR,
        error: error || 'UNKNOWN_ERROR',
      });
      return;
    }

    const deleteCount = yield call(getLikeCount, { sessionId });

    if (actionPage && actionPage === 'mypage') {
      const stateLike = yield select((state) => state.myPageData.likeData);
      const newLikeData = stateLike.filter(
        (x) => x.PresentationID !== sessionId
      );
      yield put({ type: types.UPDATE_LIKE_LIST, data: newLikeData });
    } else {
      yield put({ type: types.UPDATE_LIKE_COUNT, likeCount: deleteCount });
    }

    yield put({ type: types.UNLIKE_SESSION_SUCCESS });
  } catch (error) {
    if (error) {
      console.error('UserLikeSaga', error);
      yield put({
        type: types.UNLIKE_SESSION_ERROR,
        error,
      });
    }
  }
}

export function* getLikeCount({ sessionId }) {
  try {
    if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
      return 100;
    }
    const { data, error } = yield call(Get, {
      url: `/api/sdc/like/${sessionId}/count`,
    });

    if (error || typeof data === 'string') {
      console.error('UserLikeSaga', error);
      return 0;
    }

    return data.likeCount;
  } catch (error) {
    if (error) {
      console.error('UserLikeSaga', error);
      return 0;
    }
  }
}

// function* addLikeCount({ sessionId }) {
//   try {
//     if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
//       return 100;
//     }
//     const { data, error } = yield call(Post, {
//       url: `/api/sdc/like/${sessionId}/count`,
//     });

//     if (error || typeof data === 'string') {
//       console.error('UserLikeSaga', error);
//     }

//     return data.likeCount;
//   } catch (error) {
//     if (error) {
//       console.error('UserLikeSaga', error);
//     }
//   }
// }

// function* deleteLikeCount({ sessionId }) {
//   try {
//     if (process.env.REACT_APP_LOAD_SAMPLE_DATA) {
//       return 100;
//     }
//     const { data, error } = yield call(Delete, {
//       url: `/api/sdc/like/${sessionId}/count`,
//     });

//     if (error || typeof data === 'string') {
//       console.error('UserLikeSaga', error);
//     }

//     return data.likeCount;
//   } catch (error) {
//     if (error) {
//       console.error('UserLikeSaga', error);
//     }
//   }
// }

export default function* rootSaga() {
  yield takeLatest(types.LIKE_SESSION_REQUEST, likeSession);
  yield takeLatest(types.UNLIKE_SESSION_REQUEST, unlikeSession);
}
